import React from "react"
import {  Link } from "gatsby"
import {useAllPageData, getRouteForLocatable} from '../hooks/routing'

export default (props) => {
  let {node, locatable, className} = props
  const allPageData = useAllPageData()
  if (locatable) {
    return (
      <Link to={ getRouteForLocatable(allPageData,locatable)} className={className}>
        {props.children}
      </Link>
    )
  }
  // TODO: clean up this duplicate functionality from hooks routing
  if (node.page) {
    const sitePage = allPageData.find((page) => (page.node.context && page.node.context.id) === node.page.id )
    return (
      <Link className={className} to={`${(sitePage && sitePage.node.path)}`}>{props.children || node.label || (node.page && node.page.title)}</Link>
    )
  }
  if (node.externalLink) {
    return (
      <a href={node.externalLink} target="_blank" className={className} rel="noopener noreferrer">{props.children || node.label}</a>
    )
  }

  return (
    <button className="uk-button-reset">{node.label}</button>
  )
}
