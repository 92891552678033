import React from 'react'
import NavigationNode from "./navigation-node"
import { FaAngleDown } from 'react-icons/fa';

export default ({navigation}) => {
  return (
    <nav className="uk-background-primary uk-visible@m">
      <div className="uk-container">
          <ul className="uk-navbar-nav uk-navbar-nav-small uk-flex uk-flex-center uk-light">
            {navigation.treeChildren.slice().sort((a, b) => a.position - b.position).map( (node, i) => (
              <li key={i}>
                <NavigationNode node={node}>
                  {node.label}&nbsp;<FaAngleDown />
                </NavigationNode>
                <div className="uk-width-xlarge uk-padding-remove" uk-dropdown="">
                  <div className="uk-dropdown-grid uk-grid-match grid-xsmall" uk-grid="">
                    <div className="uk-width-3-5">
                      <ul className="background-sherpa-blue uk-nav uk-dropdown-nav uk-dropdown-nav-semibold ">
                        { node.treeChildren.filter( childNode => !childNode.secondaryLink).slice().sort((a, b) => a.position - b.position).map( (childNode, i) => (
                          <li key={i}>
                            <NavigationNode node={childNode}/>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="uk-width-2-5">
                        <ul className="uk-nav uk-dropdown-nav background-teal-blue uk-padding-small">
                          { node.treeChildren.filter( childNode => childNode.secondaryLink).slice().sort((a, b) => a.position - b.position).map( (childNode, i) => (
                            <li key={i}>
                              <NavigationNode node={childNode}/>
                            </li>
                          ))}
                        </ul>
                    </div>
                  </div>
                </div>
              </li>
            )) }
         </ul>
      </div>
    </nav>
  )
}
