import React from 'react'
import Icon from '@mdi/react';
import * as MDI from '@mdi/js';
import * as FA from 'react-icons/fa';
import * as FI from 'react-icons/fi';
import {camelCase, upperFirst} from 'lodash'
export default ({icon: {title, set}}) => {
  const classCaseTitle = upperFirst(camelCase(title))
  // TODO: font awesome 5 is a thing?
  switch(set) {
    case "Feather":
      let FIIcon = FI[`Fi${classCaseTitle}`]
      return FIIcon ?  <FIIcon size={12}/> : <p>Not an icon {title}, {set}</p>
    case "Font Awesome":
      let FAIcon = FA[`Fa${classCaseTitle}`]
      return FAIcon ?  <FAIcon size={12} /> : <p>Not an icon {title}, {set}</p>
    case "MaterialCommunityIcons":
      let mdiPath = MDI[`mdi${classCaseTitle}`]
      return  mdiPath ? <Icon path={mdiPath} size={.65} color="#897966" /> : <p>Not an icon {title}, {set}</p>
    default:
      return null
  }
}
