import { useStaticQuery, graphql } from "gatsby"

export const useAllPageData = () => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query AllPages {
        allSitePage {
          edges {
            node {
              path
              context {
                id
                slug
                isGeneratedByPage
              }
            }
          }
        }
      }
    `
  )
  return allSitePage.edges
}

export const getRouteForLocatable = (allPageData, locatable) => {
  const sitePage = allPageData.find((page) => (page.node.context && page.node.context.id) === locatable.id )
  return sitePage && sitePage.node.path
}
