import React from 'react'
import Autosuggest from 'react-autosuggest'
import { navigate } from "gatsby"
import LocatableIcon from './locatable-icon'

const renderSuggestion = suggestion => {
  return (
    <div className="uk-flex uk-flex-middle text-saddle">
      <div className="uk-background-muted uk-flex uk-flex-middle uk-flex-center uk-flex-none list-icon" >
        <LocatableIcon locatable={suggestion} />
      </div>
      <div class="uk-flex uk-flex-middle">
        {suggestion.titleOverride || suggestion.title}
      </div>
    </div>
  )
};

export default class OffcanvasMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      suggestions: []
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : this.props.locatables.filter(locatable => {
      let inKeywords = locatable.keywords && locatable.keywords.length !== 0 && locatable.keywords.find(l => l.title.toLowerCase().includes(inputValue.toLowerCase()))
      let inTitleOverride = locatable.titleOverride && locatable.titleOverride.toLowerCase().includes(inputValue.toLowerCase())
      return locatable.title.toLowerCase().includes(inputValue.toLowerCase()) || inKeywords || inTitleOverride
    });
  };

  getSuggestionValue = suggestion => suggestion.title;

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionSelected = (event, {suggestion}) => {
    navigate(suggestion.route)
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };


  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
     placeholder: 'i.e. Deals, Rides',
     value,
     onChange: this.onChange,
     autoFocus: true
   };
    return (
      <Autosuggest
       suggestions={suggestions}
       onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
       onSuggestionsClearRequested={this.onSuggestionsClearRequested}
       onSuggestionSelected={this.onSuggestionSelected}
       getSuggestionValue={this.getSuggestionValue}
       renderSuggestion={renderSuggestion}
       inputProps={inputProps}
       />
    )
  }
}
