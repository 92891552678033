import React from 'react'
import NavigationNode from './navigation-node'
import { FaStar } from 'react-icons/fa';
import Img from "gatsby-image"
const OffcanvasMenu = ({topLevelNavigation, secondaryNavigation, mobileAppTitle, googlePlay, appleStoreLink, appleStoreImage, offcanvasMenuImage}) => {
  return (
    <div id="offcanvasMenu" className="uk-light">
      <div className="uk-offcanvas-bar uk-padding-remove uk-text-uppercase uk-flex uk-flex-column">
        <div className="uk-padding-small uk-background-muted background-sherpa-blue margin-xsmall-bottom">
          <ul className="uk-nav-default" uk-nav="">
            {topLevelNavigation.treeChildren.slice().sort((a,b) => a.position - b.position).map( (node, i) => (
              <li key={i}>
                <NavigationNode className="uk-flex uk-flex-middle" node={node}>
                  <FaStar size=".7em" style={{paddingBottom: "4px"}}/>
                  &nbsp;{node.label}
                </NavigationNode>
              </li>
            )) }
          </ul>
         </div>
         <div className="uk-padding-small background-teal-blue uk-flex-1 margin-xsmall-bottom">
           <ul className="uk-nav-default uk-nav-parent-icon " uk-nav="">
             {secondaryNavigation.treeChildren.slice().sort((a,b) => a.position - b.position).map( (node, i) => (
               <li className="uk-parent" key={i}>
                 <a href={`#${node.label}`}>{node.label}</a>
                 <ul className="uk-nav-sub uk-padding-remove-top">
                   { node.treeChildren.filter( childNode => !childNode.secondaryLink).slice().sort((a,b) => parseInt(a.position) > parseInt(b.position) ? 1 : -1).map( (childNode, i) => (
                     <li key={i}>
                       <NavigationNode node={childNode}/>
                     </li>
                   ))}
                   { node.treeChildren.filter( childNode => childNode.secondaryLink).slice().sort((a,b) => parseInt(a.position) > parseInt(b.position) ? 1 : -1).map( (childNode, i) => (
                     <li key={i}>
                       <NavigationNode node={childNode}/>
                     </li>
                   ))}
                 </ul>
               </li>
             )) }
           </ul>
         </div>
         <div className="uk-padding-small background-boston-blue uk-text-center uk-flex-none uk-height-medium uk-background-norepeat uk-background-bottom-right" style={{backgroundSize: "100%"}} data-src={offcanvasMenuImage && offcanvasMenuImage.url} data-sizes={offcanvasMenuImage && offcanvasMenuImage.fluid.sizes} uk-img="" >
           <h5 >{mobileAppTitle}</h5>
           <div className="uk-flex">
             <div className="uk-width-small uk-margin-right">
               <div dangerouslySetInnerHTML={{__html: googlePlay}}></div>
               <div style={{padding: "10px"}}>
                 <a href={appleStoreLink}>
                   <Img alt="Get it in the Apple Store" fluid={appleStoreImage.fluid}/>
                 </a>
               </div>
             </div>
           </div>
         </div>
      </div>
    </div>
  )
}
export default OffcanvasMenu
