import React from 'react'
import Icon from './icon';
export default ({locatable}) => {

  switch (locatable.model && locatable.model.apiKey) {
    case "activity":
      return <Icon icon={{title: "RegCalendar", set: "Font Awesome"}} />
    case "building":
      return <Icon icon={{title: "Home", set: "MaterialCommunityIcons"}} />
    case "deal":
      return <Icon icon={{title: "Dollar Sign", set: "Font Awesome"}}  />
    case "ride":
      return <Icon icon={{title: "Ticket-alt", set: "Font Awesome"}} />
    case "service":
      return <Icon icon={{title: "Information-Outline", set: "MaterialCommunityIcons"}}  />
    case "vendor":
      return <Icon icon={{title: "Food", set: "MaterialCommunityIcons"}}  />
    case "page":
      return <Icon icon={{title: "File-outline", set: "MaterialCommunityIcons"}}   />
    case "universal_tag":
      return <Icon icon={{title: "Tag", set: "Feather"}}   />
    case "subcategory":
    case "category":
      return locatable.icon && (
        <Icon icon={locatable.icon} />
      )
    default:
      return null
  }
}
