import { useStaticQuery, graphql } from "gatsby"

export const useAllSearchableData = () => {
  return useStaticQuery(
    graphql`
      query allSearchablesQuery {
        allDatoCmsPage(filter: { root: { eq: false } }) {
          edges {
            node {
              id
              title
              titleOverride
              model {
                apiKey
              }
            }
          }
        }
        allDatoCmsCategory {
          edges {
            node {
              id
              title
              model {
                apiKey
              }
              icon {
                title
                set
              }
            }
          }
        }
        allDatoCmsSubcategory {
          edges {
            node {
              id
              title
              category
              model {
                apiKey
              }
              icon {
                title
                set
              }
            }
          }
        }
        allDatoCmsUniversalTag {
          edges {
            node {
              id
              title
              model {
                apiKey
              }
            }
          }
        }
        allDatoCmsActivity {
          edges {
            node {
              ...ActivityFields
            }
          }
        }
        allDatoCmsBuilding {
          edges {
            node {
              ...BuildingFields
            }
          }
        }
        allDatoCmsDeal {
          edges {
            node {
              ...DealFields
            }
          }
        }
        allDatoCmsRide {
          edges {
            node {
              ...RideFields
            }
          }
        }
        allDatoCmsRide {
          edges {
            node {
              ...RideFields
            }
          }
        }
        allDatoCmsService {
          edges {
            node {
              ...ServiceFields
            }
          }
        }
        allDatoCmsVendor {
          edges {
            node {
              ...VendorFields
            }
          }
        }
      }
    `
  )
}
