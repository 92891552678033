import { useStaticQuery, graphql } from "gatsby"

export const useAllSearchExceptions = () => {
  return useStaticQuery(
    graphql`
      query SearchExceptions {
        datoCmsWebsiteLayout {
          searchExceptions {
            __typename
            ... on DatoCmsPage {
              id
            }
            ... on DatoCmsCategory {
              id
            }
            ... on DatoCmsSubcategory {
              id
            }
          }
        }
      }
    `
  )
}
