import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import StickyNav from "./sticky-nav"
import Navbar from "./navbar"
import OffcanvasMenu from "./offcanvas-menu"
import OffcanvasSearch from "./offcanvas-search"
import NavigationNode from "./navigation-node"
import HeroTitleBlock from "./hero-title-block"
import ContactForm from "./contact-form"
import Img from "gatsby-image"
import '../styles/index.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteLayout {
        datoCmsWebsiteLayout {
          mobileAppTitle
          googlePlay
          appleStoreLink
          logo {
            url
            fluid(maxWidth: 400, imgixParams: { fm: "png", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          appleStoreImage {
            url
            fluid(maxWidth: 400, imgixParams: { fm: "png", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          phoneScreenshot {
            url
            fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          offcanvasMenuImage {
            url
            fluid(maxWidth: 800, imgixParams: { fm: "png", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          socialProfiles {
            ... on DatoCmsSocialProfile {
              profileType
              url
            }
          }
          stickyNavigation {
            ...NavigationNodeFields
          }
          mainNavigation {
            ...NavigationNodeFields
          }
          learnMoreNavigation {
            ...NavigationNodeFields
          }
          footerNavigation {
            ...NavigationNodeFields
          }
          defaultSearch {
            ...Items
          }
          subscribe {
            ...ContactForm
          }
          internetExplorerRejection {
            ...HeroTitleBlock
          }
        }
      }
    `}
    render={({datoCmsWebsiteLayout: {stickyNavigation, mainNavigation, logo, socialProfiles, learnMoreNavigation,
      mobileAppTitle, googlePlay, appleStoreImage, appleStoreLink, phoneScreenshot, footerNavigation,
      defaultSearch, offcanvasMenuImage, subscribe, seoMetaTags, internetExplorerRejection}}) => (
        <MainLayout>
          <OffcanvasMenu
            topLevelNavigation={stickyNavigation}
            secondaryNavigation={mainNavigation}
            mobileAppTitle={mobileAppTitle}
            googlePlay={googlePlay}
            appleStoreImage={appleStoreImage}
            offcanvasMenuImage={offcanvasMenuImage}
            />
          <OffcanvasSearch defaultSearch={defaultSearch} />
          <StickyNav navigation={stickyNavigation} logo={logo}/>
          <Navbar navigation={mainNavigation} />
          {
            (/*@cc_on!@*/false || (typeof document !== "undefined" && !!document.documentMode)) && (
              <HeroTitleBlock widget={internetExplorerRejection} />
            )
          }
          <main>
            {children}
          </main>
          <ContactForm widget={subscribe}/>
          <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container">
              <div uk-grid="" className="uk-child-width-1-1 uk-child-width-1-2@m">
                <div className="uk-flex">
                  <div className="uk-flex-none uk-margin-medium-right">
                    { socialProfiles.filter(profile => profile.url).map((profile, i) => (
                      <div className="uk-margin-bottom" key={i}>
                        <a href={profile.url}><span className="uk-icon-button" uk-icon={profile.profileType.toLowerCase()}></span></a>
                      </div>
                    )) }
                  </div>
                  <div className="uk-flex-1">
                     <h3 className="uk-text-uppercase">{learnMoreNavigation.label}</h3>
                     <ul className="uk-list">
                       { learnMoreNavigation.treeChildren.map( (node,i) => (
                         <li key={i} className="uk-text-uppercase uk-text-bold">
                           <NavigationNode node={node} />
                         </li>
                       ))}
                     </ul>
                  </div>
                </div>
                <div>
                  <h3 className="uk-text-uppercase">{mobileAppTitle}</h3>
                  <div className="uk-flex">
                    <div className="uk-width-small uk-margin-right">
                      <div dangerouslySetInnerHTML={{__html: googlePlay}}></div>
                      <div style={{padding: "10px"}}>
                        <a href={appleStoreLink}>
                          <Img alt="Get it in the Apple Store" fluid={appleStoreImage.fluid}/>
                        </a>
                      </div>
                    </div>
                    <div className="uk-flex-1">
                      {
                        phoneScreenshot && (
                          <Img alt="Mobile Washington State Fair screenshot" fluid={phoneScreenshot.fluid}/>
                        )
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="uk-background-secondary uk-section uk-section-xsmall uk-text-center">
            <div className="uk-flex uk-flex-center">
              <nav className="uk-flex uk-flex-wrap uk-light uk-margin-left uk-text-bold uk-margin-small-bottom">
                { footerNavigation.treeChildren.map( (node, i) => (
                  <div className="uk-margin-remove-top uk-margin-right " key={i}>
                    <NavigationNode node={node} className="uk-text-default"/>
                  </div>
                ))}
              </nav>
            </div>
            <p className="uk-text-muted uk-text-small uk-margin-remove">© {new Date().getFullYear()} Washington State Fair</p>
          </footer>
        </MainLayout>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

class MainLayout extends React.Component {
  componentDidMount() {
    try {
      this.UIkit = require('uikit/dist/js/uikit')
      this.Icons = require('uikit/dist/js/uikit-icons')
      this.UIkit.use(this.Icons)
    } catch (e) {
      console.error(e)
    }
  }
  render() {
    return (
      <div className="container">
        {this.props.children}
      </div>
    )
  }
}

export default Layout
