import React from 'react'
import { Link } from "gatsby"
import NavigationNode from "./navigation-node"
import { MdMenu } from 'react-icons/md';
import {FiSearch} from 'react-icons/fi';

export default (props) => (
    <div id="stickyNav" >
      <nav className="uk-navbar-container uk-navbar-sticky" uk-navbar="">
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li className="uk-hidden@m">
              <a href="#offcanvasMenu" uk-toggle=""><MdMenu size="30px" /></a>
            </li>
            <li className="uk-visible@m">
              <Link to="/">
                <div className="logo-width">
                  <img alt="The Washington State Fair" data-src={props.logo.url} uk-img=""/>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="uk-navbar-center uk-hidden@m">
          <ul className="uk-navbar-nav">
            <li>
              <Link to="/" >
                <div className="logo-width">
                  <img alt="The Washington State Fair" data-src={props.logo.url} uk-img=""/>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
              {props.navigation.treeChildren.slice().sort((a,b) => a.position - b.position).map( (node, i) => (
                <li className="uk-visible@m" key={i}>
                  <NavigationNode node={node}/>
                </li>
              )) }
              <li>
                <a href="#search" className="saddle uk-padding-remove-left" uk-toggle="" ><FiSearch size={23} /></a>
              </li>
           </ul>
        </div>
      </nav>
    </div>
)
