import React from "react"
export default ({widget: {backgroundBlend: {red, green, blue, alpha}, title, backgroundImage, image}}) => (
  <section className="uk-background-cover uk-background-norepeat uk-background-blend-soft-light uk-light"
  style={{height: "90px", backgroundColor: `rgba(${red}, ${green}, ${blue}, ${alpha})`}} data-src={backgroundImage && backgroundImage.fluid.src}
  data-sizes={backgroundImage && backgroundImage.fluid.sizes} uk-img="">
    <div className="uk-container uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
      {image ? (
        <div style={{marginTop: "10px", marginBottom: "10px"}}>
          <img alt={title} data-src={`${image.url}?h=325`} style={{maxHeight: "65px"}} className="uk-animation-fade"  uk-img="" />
        </div>
      ) : (
        <h1 className="uk-text-uppercase uk-text-center uk-margin-remove" >{title}</h1>
      )}
    </div>
  </section>
)
