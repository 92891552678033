import React from 'react'
import Search from './search'
import NavigationNode from './navigation-node'
import {getRouteForLocatable, useAllPageData} from '../hooks/routing'
import {useAllSearchableData} from '../hooks/searchables'
import {useAllSearchExceptions} from '../hooks/search-exceptions'
import LocatableIcon from './locatable-icon'


export default ({defaultSearch}) => {
  const allPageData = useAllPageData()
  const allSearchableData = useAllSearchableData()
  const allSearchExceptions = useAllSearchExceptions().datoCmsWebsiteLayout.searchExceptions
  let locatables = Object.values(allSearchableData).flatMap((l => l.edges.flatMap((edge) => edge.node)))
    .map(l => {
      return { ...l, ...{route: getRouteForLocatable(allPageData,l)}}
    })

  locatables = locatables.filter((l) => !allSearchExceptions.map(ex => ex.id).includes(l.id))
  return (
    <div id="search">
      <div className="uk-offcanvas-bar uk-padding-remove-right" style={{padding: "20px"}}>
        <div className="uk-form-label uk-margin-top">SEARCH</div>
        <div className="uk-position-relative">
          <Search locatables={locatables}/>
        </div>
        {defaultSearch && (
          <ul className="uk-list uk-list-divider">
            {
              defaultSearch && defaultSearch.items.filter(item => item.locatable).map((item, i) => (
                <li className="" key={i}>
                  <NavigationNode locatable={item.locatable} className="uk-link-text">
                    <div className="uk-flex uk-flex-middle">
                      <div className="uk-background-muted uk-flex uk-flex-middle uk-flex-center uk-flex-none list-icon" >
                        <LocatableIcon locatable={item.locatable} />
                      </div>
                      {item.titleOverride || item.locatable.title}
                    </div>
                  </NavigationNode>
                </li>
              ))
            }
          </ul>
        )}
        <button className="uk-offcanvas-close text-saddle" type="button" uk-close=""></button>
      </div>
    </div>
  )
}
