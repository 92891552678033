import React from "react"
import NavigationNode from './navigation-node'
export default ({widget: {backgroundImage, title, subtitle, buttonLabel, buttonPage}}) => (
  <section className="uk-section uk-background-top-center uk-background-cover uk-background-norepeat" data-src={backgroundImage && backgroundImage.fluid.src} data-sizes={backgroundImage && backgroundImage.fluid.sizes} uk-img="">
    <div className="uk-container">
      <div className="uk-width-xlarge uk-margin-auto uk-background-default uk-padding uk-text-center">
        <h3 className="uk-text-uppercase">{title}</h3>
        <h5 className="uk-margin-small-top">{subtitle}</h5>
        <div className="uk-flex uk-flex-center">
          {buttonLabel && buttonPage && (
            <NavigationNode locatable={buttonPage} className="uk-button uk-button-secondary uk-text-nowrap">{buttonLabel}</NavigationNode>
          )}
        </div>
      </div>
    </div>
  </section>
)
